@media screen and (min-width: 768px) {
  .container {
    display: flex !important;
    justify-content: center;
  }
  .content {
    max-width: 472px;
  }
}
.link {
  color: #000;

  text-decoration: underline;
  font-weight: 700;
}
