.container{
    display: flex;
    flex-direction: column;
}

.titleWrap{
    margin-top: 20px;
    align-self: center;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
}

.badgesWrap{
    /* padding: 20px 15px; */
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #96969692;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }