.authPages {
  width: 88vw;
  margin: 4rem auto 0;
}

.authPages img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.authPages h2 {
  color: var(--grey-color);
  font-size: 1.75rem;
  margin-top: 1rem;
  text-align: center;
  font-weight: 750;
  margin-bottom: 3rem;
}

.imgContainer {
  width: 90%;
  margin: auto;
}

/* LOGIN FORM */
.LoginForm {
  display: flex;
  justify-content: center;
  border: 1px solid var(--grey-color);
  border-radius: 8px;
  padding: 3px 0;
}

.LoginForm:hover .LoginBtn {
  background-color: var(--green-color);
}

.LoginForm input,
.LoginForm button {
  background-color: transparent;
  border: none;
}

.LoginForm input {
  color: var(--grey-color);
  width: 85%;
  margin-right: auto;
  font-size: 1rem;
  text-align: center;
  font-family: var(--font-family);
}

.LoginForm button {
  cursor: pointer;
}

.LoginBtn {
  font-size: 0.875rem;
  font-weight: 750;
  color: var(--white-color);
  background-color: var(--red-color);
  border-radius: 8px;
  padding: 0.75rem 2.6rem;
  font-family: var(--font-family);
}

.LoginForm input:focus {
  outline: none;
}

.LoginForm input::placeholder {
  color: var(--grey-color);
  font-size: 0.875rem;
  font-weight: 750;
  font-family: var(--font-family);
}

/* SUBSCRIBE BUTTON */
.SubscribeBtn{
    background-color: var(--green-color);
    text-align: center;
    color: var(--white-color);
    border-radius: 8px;
    padding: 0.7rem 2.6rem;
}