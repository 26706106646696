.sidebar {
  flex: 1;
  min-height: 100vh;
  /* background-color: var(--orange-color); */
  background-color: var(--green-color);
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar ul {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.sidebar li {
  font-weight: 700;
}

.navBtn {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
