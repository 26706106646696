.btn {
  width: 18.5rem;
  height: 40px;
  /* text-align: center; */
  color: var(--green-color);
  background-color: var(--white-color);
  border-radius: 0.8rem;
  border: 1px solid var(--green-color);
  text-transform: capitalize;
}
.btn:hover {
  background-color: var(--green-color);
  color: var(--white-color);
}
.btn--subscribe {
  background-color: var(--red-color);
  border-radius: 0.5rem;
  color: var(--white-color);
  border: none;
}
.btn--plan {
  width: 7.1rem;
  border-radius: 0.5rem;
}
.btn--home {
  background-color: var(--yellow-color);
  border: none;
  color: var(--white-color);
  border-radius: 0.5rem;
  margin-top: 2rem;
}
.btn--disabled {
  background-color: var(--grey-3);
  border: none;
  color: var(--green-color);
  border-radius: 0.5rem;
  /* margin-top: 2rem; */
}
.btn--disabled:hover {
  background-color: var(--grey-3);
  color: var(--green-color);
}
.btn--planActive {
  width: 7.1rem;
  border-radius: 0.5rem;
  background-color: var(--green-color);
  color: var(--white-color);
}
.btn--smallBtnDisabled {
  width: 7.1rem;
  border-radius: 0.5rem;
  background-color: var(--grey-3);
  color: var(--green-color);
  border: none;
}
.btn--smallBtnDisabled:hover {
  background-color: var(--grey-3);
  color: var(--green-color);
}
.btn--active {
  background-color: var(--green-color);
  color: var(--white-color);
}
.btn--modal {
  background-color: var(--green-color);
  color: var(--white-color);
  width: 100%;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 300px) {
  .btn {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .btn {
    /* width: 100%; */
  }
}
