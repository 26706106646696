.QQM {
  padding: 0% 15% 4% 15%;
  display: flex;
  /* background-image: url(/src/assets/images/Transparent-background-image.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #d4f0f99a;
  min-height: 90vh;
}

.QQM-container {
  margin: 0 auto;
  max-width: 1340px;
  width: 100%;
}

.QQM-questTitleBar {
  margin-top: 20px;
  background-color: #f32a39;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 15px;
  color: white;
  gap: 10px;
  justify-content: space-between;
}

.QQM-topiWrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.QQM-qtnCounter {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.QQM-counter {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
}

.QQM-timerBar {
  width: 500px;
  background-color: white;
  height: 20px;
  border-radius: 20px;
}

.QQM-inner {
  /* width: 300px; */
  height: 20px;
  background-color: #1fbceb;
  border-radius: 20px;
}

.CircularProgressbar {
  font-size: 20px;
  height: 10%;
  width: 10px;
}

.QQM-progressbarWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.QQM-timeLeft {
  font-size: 15px;
  display: flex;
  font-weight: bold;
}

.QMM-circle {
  width: 70px;
  height: 60px;
  stroke: green;
  border-radius: 50%;
  font-size: 20px;
}

.CircularProgressbar-path {
  stroke: red;
}

.CircularProgressbar-trail {
  stroke: gray;
}

.QQM-QuestionAndAnswer {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  gap: 20px;
}

.QQM-questionCard {
  max-width: 450px;
  width: 100%;
  background-color: #fbfdfe;
  min-height: 250px;
  padding: 35px 20px;
  border-radius: 15px;
  font-size: 30px;
  -webkit-user-select: none;
  user-select: none;
}

.QQM-answerCard {
  max-width: 490px;
  width: 100%;
  height: 250px;
  padding: 17px 0px;
  border-radius: 15px;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.QQM-chooseAnswer {
  display: flex;
  align-items: center;
  padding: 13px 10px;
  border: 3px solid gray;
  border-radius: 25px;
  display: flex;
  align-items: center;
  background-color: #fbfdfe;
  cursor: pointer;
}

.QQM-chooseAnswerActive {
  display: flex;
  align-items: center;
  padding: 13px 10px;
  border: 3px solid gray;
  border-radius: 25px;
  display: flex;
  align-items: center;
  background-color: #1fbceb;
  cursor: pointer;
}

.QQM-chooseAnswer input[type="checkbox"] {
  accent-color: #d92b58;
  border-radius: 50%;
  width: 100px;
  height: 20px;
}

.QQM-qtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  font-weight: bold;
}

.QQM-buttnWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.QQM-buttnWrap button {
  background-color: #f32a39;
  color: white;
  max-width: 150px;
  width: 100%;
  border-radius: 15px;
  height: 40px;
  cursor: pointer;
}

.QQM-quzi {
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
  height: fit-content;
  z-index: 1;
}

.QQM-counterWrapCircle {
  height: 40px;
  width: 40px;
  /* border: 10px solid white; */
  border-radius: 50%;
  stroke-width: 10px;
  stroke: #d92b58;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.QQM-counterCircle {
  font-size: 20px;
  font-weight: bold;
  color: black;
  position: absolute;
  right: 0%;
  left: 45%;
  bottom: 45%;
  top: 35%;
  z-index: 1;
}

.QQM-checkboxWrap {
  display: flex;
  align-items: center;
}

.QQM-answers {
  font-size: 16px;
  font-weight: bold;
}

.QQM-checkbox {
  min-height: 30px;
  min-width: 30px;
}

.QQM input[type="checkbox"] {
  accent-color: #1fbceb;
  color: white;
}

@media (max-width: 930px) {
  .QQM {
    padding: 0% 5% 50% 5%;
  }
}

@media (max-width: 650px) {
  .QQM {
    padding: 0% 5% 50% 5%;
  }

  .QQM-QuestionAndAnswer {
    flex-direction: column;
  }

  .QQM-questionCard {
    max-width: unset;
    width: unset;
    min-height: unset;
    font-size: 22px;
    font-weight: 500;
  }

  .QQM-qtn {
    font-size: 17px;
  }

  .QQM-questTitleBar {
    background-color: #f32a39;
    width: unset;
  }

  .QQM-answerCard {
    max-width: unset;
    width: unset;
  }

  .QQM-buttnWrap {
    margin-bottom: 150px;
  }
}

@media (max-width: 620px) {
  .QQM-christmassText {
    font-size: 18px;
  }
  .QQM-questionCard {
    font-size: 1rem;
    padding: 15px;
  }
  .QQM-qtn {
    justify-content: flex-start;
    font-size: 0.8rem;
    padding-left: 8px;
  }
  .QQM-chooseAnswer {
    padding: 10px;
  }
}

@media (max-width: 510px) {
  .QQM-christmassText {
    font-size: 18px;
  }

  .QQM-questTitleBar {
    background-color: #f32a39;
    width: unset;
    flex-direction: column;
  }
}

@media (max-width: 440px) {
  .QQM-christmassText {
    font-size: 15px;
  }
}

@media (max-width: 388px) {
  .QQM-topiWrap {
    flex-direction: column;
  }

  .QQM-christmassText {
    font-size: 5vw;
    white-space: nowrap;
  }
}
