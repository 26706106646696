

.titleWrap{
  height: 8vh;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  gap: 8px;
}



.closeIcon{
  color: red;
  font-size: 25px;
  min-width: 50px;
}

.gamePlayWrap{
  padding: 0 10px;
  height: 90vh;
}


.rotateNotiice{
  display: none;
}
  
  
 .appleGamePlay{
  display: none;
 }





@media  only screen and  (orientation:portrait) {
    .rotateNotiice{
        display: block;
    }

    .appleGamePlay{
        display: none;
    }
  }

  @media only screen and (orientation:landscape) {
    .rotateNotiice{
        display: none;
    }

    .appleGamePlay{
        display: block;
        padding: 0 10px;
      height: 90vh;
    }
  }